@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "American Typewriter";
  src: url("../public/fonts/American\ Typewriter\ Regular.ttf");
}

@font-face {
  font-family: "American Typewriter bold";
  src: url("../public/fonts/American\ Typewriter\ Bold.ttf");
}

.width40 {
  width: 40%;
}

.width60 {
  width: 60%;
}

.w-100 {
  width: 100%;
}
.width35 {
  width: 35%;
}

.width65 {
  width: 65%;
}

.width33 {
  width: 33%;
}

.w99 {
  width: 99%;
}

.w-35 {
  width: 35%;
}

.purpletxt {
  color: #aca3ec;
}

.purplebg {
  background-color: #3d3a53;
}

.width23 {
  width: 23.5%;
}

.boldtypewriter {
  font-family: "typewriter";
  font-weight: 900;
}

.h-1 {
  height: 6px;
}

.h-01 {
  height: 1px;
}
